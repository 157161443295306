import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import bootstrap from 'bootstrap/dist/js/bootstrap'
window.bootstrap = require('bootstrap/dist/js/bootstrap')
import 'controllers'
import '../stylesheets/silicon'
import 'boxicons/css/boxicons.min.css'
import 'packs/silicon/swiper'
import 'packs/initialize'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
