import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/swiper-bundle.min.css'

document.addEventListener('DOMContentLoaded', () => {
  ;(() => {
    // forEach function
    let forEach = (array, callback, scope) => {
      for (let i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]) // passes back stuff we need
      }
    }

    // Carousel initialisation
    let carousels = document.querySelectorAll('.swiper')
    forEach(carousels, (index, value) => {
      let userOptions, pagerOptions
      if (value.dataset.swiperOptions != undefined)
        userOptions = JSON.parse(value.dataset.swiperOptions)

      // Slider init
      const options = {
        modules: [Navigation, Pagination, Autoplay],
        ...userOptions,
        ...pagerOptions
      }

      console.log(options)

      const swiper = new Swiper(value, options) // eslint-disable-line no-undef

      // Tabs (linked content)
      if (userOptions.tabs) {
        swiper.on('activeIndexChange', (e) => {
          let targetTab = document.querySelector(
              e.slides[e.activeIndex].dataset.swiperTab
            ),
            previousTab = document.querySelector(
              e.slides[e.previousIndex].dataset.swiperTab
            )
          previousTab.classList.remove('active')
          targetTab.classList.add('active')
        })
      }
    })
  })()
})
